.alt-sign-in-button {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transform: scale(1);
  transition: 150ms ease-in-out;
  user-select: none;
  &:hover {
    // scale: 1.1;
    transform: scale(0.97);
  }
  &:active {
    // scale: 1.1;
    transform: scale(0.94);
  }
}
