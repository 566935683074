.text-input {
  position: relative;
  width: 340px;
  max-width: 90%;

  #label {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.6);
  }

  #optional {
    position: absolute;
    right: 6px;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 4px;
  }

  input {
    color: rgba(255, 255, 255, 0.85);
    padding: 8px 12px;
    box-sizing: border-box;
    background: 0 0;
    height: 46px;
    font-size: 18px;
    margin-top: 4px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    width: 100%;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    overflow: hidden;

    &:focus {
      border-color: #3a86ff;
    }
  }
}
