.input-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 800px) {
    flex-direction: row;
    .text-input {
      width: 200px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

#city-state-input {
  display: flex;
  flex-direction: row;

  #city-input {
    width: 225px;
    margin-right: 10px;
  }
  #state-input {
    width: 70px;
  }

  @media (min-width: 800px) {
    #city-input {
      width: 120px;
      margin-right: 5px;
    }
  }
}
