html {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: "SFProTextRegular";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color-scheme: dark;

  #background-video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    display: none;
  }
  @media (min-width: 1100px) {
    #background-video {
      display: initial;
    }
  }
}

@font-face {
  font-family: "Azonix";
  src: local("Azonix"), url(./assets/fonts/azonix/azonix.otf) format("truetype");
}

@font-face {
  font-family: "SFProTextRegular";
  src: local("SFProTextRegular"),
    url(./assets/fonts/sfProText/sfProTextRegular.ttf) format("truetype");
}

@font-face {
  font-family: "SFProTextSemibold";
  src: local("SFProTextSemibold"),
    url(./assets/fonts/sfProText/sfProTextSemibold.otf) format("truetype");
}

@font-face {
  font-family: "SFProTextBold";
  src: local("SFProTextBold"),
    url(./assets/fonts/sfProText/sfProTextBold.ttf) format("truetype");
}

@font-face {
  font-family: "SFMonoRegular";
  src: local("SFMonoRegular"),
    url(./assets/fonts/sfMono/sfMonoRegular.otf) format("truetype");
}

@font-face {
  font-family: "SFMonoSemibold";
  src: local("SFMonoSemibold"),
    url(./assets/fonts/sfMono/sfMonoSemibold.otf) format("truetype");
}

@font-face {
  font-family: "GothamBlack";
  src: local("GothamBlack"),
    url(./assets/fonts/gotham/gothamBlack.otf) format("truetype");
}
