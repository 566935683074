.account-details-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  #email-display {
    color: rgba(255, 255, 255, 0.6);
    font-family: SFProTextSemibold;
    font-size: 16px;
    margin-top: 10px;
  }
}
