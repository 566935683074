.sign-in-button {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transform: scale(1);
  transition: 150ms ease-in-out;
  user-select: none;
  border: none;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
  color: white;
  font-size: 14px;
  font-family: SFProTextSemibold;
  margin-top: 20px;
  cursor: pointer;

  background: linear-gradient(
    to right,
    #1664f1,
    #871bed,
    #a606e4,
    #d31fbb,
    #f23897,
    #ff4571
  );

  &:hover {
    // scale: 1.1;
    transform: scale(0.97);
  }
  &:active {
    // scale: 1.1;
    transform: scale(0.94);
  }
}
