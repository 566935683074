.auth-page-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;

  #form-div {
    position: absolute;
    box-sizing: border-box;
    height: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(30px);
    background: radial-gradient(
        ellipse at bottom,
        rgba(20, 20, 20, 0.85),
        #141414
      ),
      radial-gradient(ellipse at top, #3a86ff, transparent),
      radial-gradient(ellipse at bottom, #f23897, transparent);

    #fwaygo-logo {
      width: 200px;
      max-width: 80%;
      margin-bottom: 4px;
    }

    #go-back {
      position: absolute;
      width: 40px;
      top: 30px;
      left: 30px;
      cursor: pointer;
      opacity: 0.4;
      transition: 100ms opacity ease-in-out;
      &:hover {
        opacity: 0.6;
      }
      // max-width: 80%;
      // margin-bottom: 4px;
    }

    #header {
      margin-top: 12px;
      color: white;
    }
    #error {
      margin-top: 12px;
      font-size: 12px;
      font-family: SFProTextSemibold;
      color: white;
    }
  }

  @media (min-width: 1100px) {
    #form-div {
      min-width: 42%;
      background: radial-gradient(
        ellipse at bottom,
        rgba(20, 20, 20, 0.5),
        #141414
      );
    }
    #background-video {
      display: initial;
    }
  }
}
